import { useRuntimeConfig } from '#imports';

/**
 * 获取存储对象的统一访问路径
 * @description 此路径下最终是在api/storage/visit这个端口里面处理的动态跳转逻辑
 * @param path  string
 * @param provider string
 * @param useInner boolean 是否使用内网地址
 * @returns 
 */
const getVisitUrl = (path: string, provider?: string, useInner?: boolean) => {
  path = path || '';
  if (path.startsWith('http')) {
    return path
  }
  if (!path.startsWith('/')) {
    path = '/' + path
  }
  let url = `/api/storage/visit${path}`
  let queryParam = [];
  if (provider) {
    queryParam.push(`provider=${provider}`)
  }
  if (useInner === true) {
    queryParam.push('userInner=true')
  }

  if (queryParam.length > 0) url += `?${queryParam.join("&")}`

  return url
}

/**
 * 获取存储对象真实的访问链接
 * @param path  string
 * @param provider  'local'| 's3' | 'LOCAL' | 'S3' 
 * @param useInner boolean 是否使用内网地址
 * @returns 
 */
const getVisitRealUrl = (path: string, provider?: 'local' | 's3' | 'LOCAL' | 'S3', useInner?: boolean) => {
  const config = useRuntimeConfig();
  let visitUrl = '';
  if (provider.toLocaleLowerCase() === 'local') {
    visitUrl = `${useInner ? config.public.innerFileUrl : config.public.fileUrl}${path}`;
  } else if (provider.toLocaleLowerCase() === 's3') {
    const innerStorageUrl = config.public.innerStorageUrl || config.storage.upload.option['tus_s3'].endpoint;
    visitUrl = `${useInner ? innerStorageUrl : config.public.storageUrl}${path}`;
  }

  return visitUrl;
}

export {
  getVisitUrl,
  getVisitRealUrl
}